/** @jsx jsx */
//import * as React from "react"
import { jsx, css } from "@emotion/react"
import { SEO } from "../../components/seo"
import Page from "../../layouts/page"

import { Link } from "gatsby"

const styles = css`
table {
  display: block;
  width: 65%;

  .payments-accepted {
    text-align: center;
  }
  .payments-notes {
    font-weight: lighter;
  }
}

@media (max-width: 1000px) {
  table {
    width: auto;
  }
}
`

const InfoPaymentsPage = () => {
  return <Page>
    <div css={styles}>
      <div className="contain page-body">
        <ol class="uk-breadcrumb">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/info">Information</Link></li>
          <li><span>Payments</span></li>
        </ol>
        
        <h1>Payments</h1>

        <p>For any queries regarding billing, please <Link to="/contact-us">contact us</Link>.</p>

        <h2>How to pay</h2>

        <p>We accept several methods of payment. When you make a payment to our bank you must quote your account or invoice number as the unique reference.</p>

        <table className="uk-table uk-table-divider uk-table-striped uk-overflow-auto">
          <thead>
            <tr>
              <th>Method of payment</th>
              <th className="payments-accepted">Standard services</th>
              <th className="payments-accepted">Long term services</th>
              <th class="uk-table-expand">Notes</th>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td>Faster Payments (UK)</td>
              <td className="payments-accepted"><span uk-icon="icon: check"></span></td>
              <td className="payments-accepted"><span uk-icon="icon: check"></span></td>
              <td className="payments-notes">Preferred for UK</td>
            </tr>
            <tr>
              <td>BACS (UK)</td>
              <td className="payments-accepted"><span uk-icon="icon: check"></span></td>
              <td className="payments-accepted"><span uk-icon="icon: check"></span></td>
              <td className="payments-notes"></td>
            </tr>
            <tr>
              <td>CHAPS (UK)</td>
              <td className="payments-accepted"><span uk-icon="icon: check"></span></td>
              <td className="payments-accepted"><span uk-icon="icon: check"></span></td>
              <td className="payments-notes"></td>
            </tr>
            <tr>
              <td>Wire transfer</td>
              <td className="payments-accepted"><span uk-icon="icon: check"></span></td>
              <td className="payments-accepted"><span uk-icon="icon: check"></span></td>
              <td className="payments-notes">Preferred for non-UK</td>
            </tr>
            <tr>
              <td>Direct debit</td>
              <td className="payments-accepted"><span uk-icon="icon: check"></span></td>
              <td className="payments-accepted"><span uk-icon="icon: check"></span></td>
              <td className="payments-notes"></td>
            </tr>
            <tr>
              <td>PayPal</td>
              <td className="payments-accepted"><span uk-icon="icon: check"></span></td>
              <td className="payments-accepted"><span uk-icon="icon: close"></span></td>
              <td className="payments-notes"></td>
            </tr>
            <tr>
              <td>Online credit card</td>
              <td className="payments-accepted"><span uk-icon="icon: check"></span></td>
              <td className="payments-accepted"><span uk-icon="icon: close"></span></td>
              <td className="payments-notes"></td>
            </tr>
          </tbody>
        </table>

        <h3>Payment options</h3>

        <h4>Bank transfers - UK payments</h4>

        <p>
          When you make a payment to our bank you must quote your account or invoice number as the unique reference.<br/>
        </p>
        <p>
          The relevant bank details can be found on your invoice.
        </p>

        <h4>Wire transfers - non-UK payments</h4>

        <p>
          When you make a payment to our bank you must quote your account or invoice number as the unique reference.<br/>
          Please quote our bank details plus our SWIFT and IBAN codes.<br/>
          Payments can take more than 6 working days to reach our account, so please allow sufficient time for your wire transfer to be processed.<br/>
          You must pay all service charges and commissions that are incurred through-out the transaction.
        </p>
        <p>
          The relevant bank details, SWIFT and IBAN codes can be found on your invoice.
        </p>
        <p>
          If your invoice does not show up as paid within 6 working days, please <Link to="/contact-us">get in touch with us</Link>.
        </p>

        <h4>PayPal</h4>

        <p>
          PayPal payments can be made from the specific invoice within the Customer Portal.
        </p>

        <h4>Online credit card</h4>

        <p>
          Credit card payments can be made from the specific invoice within the Customer Portal.
        </p>
      </div>
    </div>
  </Page>
}

export const Head = () => (
  <SEO
    title="Payments"
    path="/info/payments"
  />
)

export default InfoPaymentsPage
